<template>
  <div>
    <!-- Common information modal -->
    <ui-modal
      v-bind="$attrs"
      v-on="$listeners"
      no-footer
      class="staff-info-modal"
      @before-open="loadInfo"
    >
      <template #title>
        <div class="modal-title text-center">
          {{ title }}
        </div>
      </template>

      <template #default="{ close }">
        <ui-loading v-if="loading" />
        <div v-else-if="staff" class="container">
          <div
            v-for="item in visibleAttributes"
            :key="item.id"
            class="row"
          >
            <div
              class="col-12 col-md py-3"
              :class="{ 'text-gray': item.muted }"
            >
              <strong>{{ item.name }}</strong>
            </div>
            <div
              class="col-12 col-md-auto ml-auto py-3 d-flex align-items-center"
              :class="{ 'text-gray': item.muted }"
            >
              <template v-if="isPhoto(item)">
                <span
                  class="link"
                  @click="downloadPhotoScan(item.value)"
                >
                  Download
                </span>
                <span class="mx-1">/</span>
                <span
                  class="link"
                  @click="openPhotoScan(item.value)"
                >
                  Open
                </span>
              </template>
              <template v-else-if="isReport(item)">
                <span
                  class="link"
                  @click="getMonthlyReport(item.value)"
                >
                  Open
                </span>
              </template>
              <template v-else-if="item.value">
                <span class="mr-2">{{ item.value }}</span>
                <ui-text-copy :text="item.value"/>
              </template>
              <span v-else :class="{ 'text-muted': !item.muted }">
                {{ item.empty }}
              </span>
            </div>
          </div>

          <div class="row justify-content-around">
            <template v-for="buttonName in visibleButtons">
              <ui-text-copy
                v-if="buttonName === 'COPY_INFO'"
                :key="buttonName"
                :text="textInfo"
                v-slot="{ copyTextInfo }"
              >
                <ui-button
                  type="primary"
                  class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12 p-3 mt-3"
                  :outline="isButtonOutlined(buttonName)"
                  block
                  @click.prevent="copyTextInfo"
                >
                  Copy info
                </ui-button>
              </ui-text-copy>
              <ui-button
                v-if="buttonName === 'VIEW_AS'"
                :key="buttonName"
                type="primary"
                class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12 p-3 mt-3"
                  :outline="isButtonOutlined(buttonName)"
                block
                @click.prevent="viewAs"
              >
                View as
              </ui-button>
              <ui-button
                v-if="buttonName === 'CLOSE'"
                :key="buttonName"
                type="primary"
                class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12 p-3 mt-3"
                :outline="isButtonOutlined(buttonName)"
                block
                @click.prevent="close"
              >
                Close
              </ui-button>
            </template>
          </div>
        </div>
      </template>
    </ui-modal>

    <!-- Photo scan modal -->
    <ui-modal
        v-model="photoScan.show"
        size="xl"
        class="staff-info-scan"
        no-footer
      >
        <ui-loading v-if="photoScan.loading" />
        <img
          v-else-if="photoScan.src"
          :src="photoScan.src"
          alt="scan"
        />
      </ui-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { DateTime } from 'luxon';
import { ALL_TAX_REPORTING_TYPES } from '@/config/taxReportingTypes';
import api from '@/api';
import parseDate from '@/filters/date';
import download from '@/utils/download';
import open from '@/utils/open';
import getFio from '@/utils/fio';
import paymentTypes from '@/config/paymentTypes';
import makeStaffDetails from '@/utils/presenters/staffDetails';

export const STAFF_INFO_ATTRIBUTES = [
  'FIRST_NAME',
  'LAST_NAME',
  'ADDRESS',
  'BANK_NAME',
  'IBAN',
  'SWIFT_OR_BIC',
  'NAME',
  'EMAIL',
  'LOGIN',
  'PHONE_NUMBER',
  'STRIPE_ACCOUNT',
  'FULL_LEGAL_NAME',
  'BIRTH_DATE',
  'PASSPORT_ID',
  'COUNTRY_ID',
  'NATIONALITY',
  'ID_PHOTO_FRONT',
  'ID_PHOTO_BACK',
  'APP_INSTALLED',
  'APP_ENTRY_DATE',
  'APP_VERSION',
  'USAGE_FREQUENCY',
  'STAFF_PAYOUT_ID',
  'LOCATION_NAME',
  'STAFF_TYPE',
  'TAX_REPORTING',
  'TIPS_TYPE',
  'PAYMENT_SYSTEM',
  'PAYMENT_STATUS',
  'SHARED_CARD',
  'ID_PHOTO_SCAN',
  'MONTHLY_REPORT',
];

export const STAFF_INFO_BUTTONS = [
  'COPY_INFO',
  'VIEW_AS',
  'CLOSE',
];

export default {
  name: 'StaffInfoModal',

  props: {
    title: {
      type: String,
      required: false,
      default: 'Staff info',
    },
    staff: {
      type: Object,
      required: true,
    },
    location: {
      type: Object,
      required: true,
    },
    attributes: {
      type: Array,
      required: true,
      validator: (value) => {
        return value.every((attr) => STAFF_INFO_ATTRIBUTES.includes(attr));
      },
    },
    buttons: {
      type: Array,
      required: true,
      validator: (value) => {
        return value.every((btn) => STAFF_INFO_BUTTONS.includes(btn));
      },
    },
  },

  data: () => ({
    info: null,
    loading: false,
    photoScan: {
      show: false,
      loading: false,
      src: null,
    },
  }),

  computed: {
    ...mapGetters('auth', [
      'isAdmin',
      'isViewAsAccess',
    ]),
    allAttributes() {
      if (!this.info) {
        return [];
      }

      return [
        {
          id: 'FIRST_NAME',
          name: 'First Name',
          value: this.info.firstName,
          empty: 'No first name',
          visible: true,
          muted: false,
          skipCopy: false,
        },
        {
          id: 'LAST_NAME',
          name: 'Last Name',
          value: this.info.lastName,
          empty: 'No last name',
          visible: true,
          muted: false,
          skipCopy: false,
        },
        {
          id: 'ADDRESS',
          name: 'Address',
          value: this.info.address,
          empty: 'No address',
          visible: true,
          muted: false,
          skipCopy: false,
        },
        {
          id: 'BANK_NAME',
          name: 'BANK NAME',
          value: this.info.bankName,
          empty: 'No bank name',
          visible: true,
          muted: false,
          skipCopy: false,
        },
        {
          id: 'IBAN',
          name: 'IBAN',
          value: this.info.iban,
          empty: 'No IBAN',
          visible: true,
          muted: false,
          skipCopy: false,
        },
        {
          id: 'SWIFT_OR_BIC',
          name: 'SWIFT / BIC',
          value: this.info.swiftOrBic,
          empty: 'No SWIFT / BIC',
          visible: true,
          muted: false,
          skipCopy: false,
        },
        {
          id: 'NAME',
          name: 'Name',
          value: this.info.fio || getFio(this.info),
          empty: 'No name',
          visible: true,
          muted: false,
          skipCopy: false,
        },
        {
          id: 'EMAIL',
          name: 'Email',
          value: this.info.email,
          empty: 'No email',
          visible: true,
          muted: false,
          skipCopy: false,
        },
        {
          id: 'LOGIN',
          name: 'Login',
          value: this.info.login,
          empty: 'No login',
          visible: process.env.NODE_ENV === 'development',
          muted: false,
          skipCopy: false,
        },
        {
          id: 'PHONE_NUMBER',
          name: 'Phone number',
          value: this.info.phoneNumber,
          empty: 'No phone number',
          visible: true,
          muted: false,
          skipCopy: false,
        },
        {
          id: 'STRIPE_ACCOUNT',
          name: 'Stripe account',
          value: this.info.stripeAccount,
          empty: 'No account',
          visible: true,
          muted: this.info.paymentSystem !== 'STRIPE',
          skipCopy: false,
        },
        {
          id: 'FULL_LEGAL_NAME',
          name: 'Full Legal Name',
          value: this.info.fullLegalName,
          empty: '',
          visible: Boolean(this.info.fullLegalName),
          muted: false,
          skipCopy: false,
        },
        {
          id: 'BIRTH_DATE',
          name: 'Date of Birth',
          value: this.info.dateOfBirth
            ? parseDate(this.info.dateOfBirth)
            : null,
          empty: '',
          visible: Boolean(this.info.dateOfBirth),
          muted: false,
          skipCopy: false,
        },
        {
          id: 'PASSPORT_ID',
          name: 'Passport/ID number',
          value: this.info.governmentIssuedIdNumber,
          empty: '',
          visible: Boolean(this.info.governmentIssuedIdNumber),
          muted: false,
          skipCopy: false,
        },
        {
          id: 'COUNTRY_ID',
          name: 'ID number Issuing Country',
          value: this.info.issuingCountryIsoCode
            ? this.$t(`countries.${this.info.issuingCountryIsoCode}`)
            : null,
          empty: '',
          visible: Boolean(this.info.issuingCountryIsoCode),
          muted: false,
          skipCopy: false,
        },
        {
          id: 'NATIONALITY',
          name: 'Nationality',
          value: this.info.nationality?.name || null,
          empty: '',
          visible: Boolean(this.info.nationality),
          muted: false,
          skipCopy: false,
        },
        {
          id: 'ID_PHOTO_FRONT',
          name: 'ID photo front',
          value: 'FRONT',
          empty: '',
          visible: Boolean(this.info.idFrontPhotoUploaded),
          muted: false,
          skipCopy: true,
        },
        {
          id: 'ID_PHOTO_BACK',
          name: 'ID photo back',
          value: 'BACK',
          empty: '',
          visible: Boolean(this.info.idBackPhotoUploaded),
          muted: false,
          skipCopy: true,
        },
        {
          id: 'ID_PHOTO_SCAN',
          name: 'ID photo front',
          value: this.info.frontSideDocumentId,
          empty: '',
          visible: Boolean(this.info.frontSideDocumentId),
          muted: false,
          skipCopy: true,
        },
        {
          id: 'APP_INSTALLED',
          name: 'App installed',
          value: this.info.appInstalled
            ? this.$t('appInstalled.installed')
            : this.$t('appInstalled.notInstalled'),
          empty: '',
          visible: true,
          muted: false,
          skipCopy: false,
        },
        {
          id: 'APP_ENTRY_DATE',
          name: 'Last app entry',
          value: this.info.lastAppEntry
            ? parseDate(this.info.lastAppEntry)
            : null,
          empty: 'Not installed',
          visible: true,
          muted: false,
          skipCopy: false,
        },
        {
          id: 'APP_VERSION',
          name: 'Mobile App',
          value: this.info.mobileAppVersion,
          empty: 'Not installed',
          visible: true,
          muted: false,
          skipCopy: false,
        },
        {
          id: 'USAGE_FREQUENCY',
          name: 'Usage frequency',
          value: this.info.usageFrequency || '0',
          empty: '',
          visible: true,
          muted: false,
          skipCopy: false,
        },
        {
          id: 'MONTHLY_REPORT',
          name: 'Monthly Report',
          value: '',
          empty: '',
          visible: true,
          muted: false,
          skipCopy: true,
        },
        {
          id: 'STAFF_PAYOUT_ID',
          name: 'EasyTip ID',
          value: this.info.payoutId,
          empty: 'No EasyTip ID',
          visible: true,
          muted: false,
          skipCopy: false,
        },
        {
          id: 'LOCATION_NAME',
          name: 'Location',
          value: this.info.location.name,
          empty: 'No location',
          visible: true,
          muted: false,
          skipCopy: false,
        },
        {
          id: 'STAFF_TYPE',
          name: 'Staff type',
          value: this.info.staffType
            ? this.$getStaffTypeName(this.info.staffType)
            : null,
          empty: 'No staff type',
          visible: true,
          muted: false,
          skipCopy: false,
        },
        {
          id: 'TAX_REPORTING',
          name: 'Tax reporting',
          value: this.getTaxReportingTypeValue(this.info),
          empty: 'No tax reporting',
          visible: true,
          muted: false,
          skipCopy: false,
        },
        {
          id: 'TIPS_TYPE',
          name: 'Tips collection type',
          value: this.getTipsTypeValue(this.info),
          empty: 'No tips collection type',
          visible: true,
          muted: false,
          skipCopy: false,
        },
        {
          id: 'PAYMENT_SYSTEM',
          name: 'Gateway',
          value: paymentTypes[this.info.paymentSystem] || null,
          empty: 'No gateway',
          visible: true,
          muted: false,
          skipCopy: false,
        },
        {
          id: 'PAYMENT_STATUS',
          name: 'KYC Status',
          value: this.info.paymentStatus
            ? this.$t(`paymentStatus.${this.info.paymentStatus}`)
            : null,
          empty: 'No status',
          visible: true,
          muted: false,
          skipCopy: false,
        },
        {
          id: 'SHARED_CARD',
          name: 'Shared Card',
          value: this.getSharedCardValue(this.info),
          empty: '',
          visible: true,
          muted: false,
          skipCopy: false,
        },
      ].filter(({ visible }) => visible);
    },
    visibleAttributes() {
      return this.attributes
        .map((attr) => this.allAttributes.find((item) => item.id === attr))
        .filter(Boolean);
    },
    visibleButtons() {
      return this.buttons.filter((buttonName) => {
        if (buttonName === 'VIEW_AS') {
          return this.showViewAs;
        }

        return true;
      });
    },
    showViewAs() {
      return this.isViewAsAccess;
    },
    textInfo() {
      const attributes = this.info.copyValueMap
        ? Object.entries(this.info.copyValueMap).map(([name, value]) => ({ name, value }))
        : [
          {
            name: 'User ID',
            value: this.staff.id,
          },
          {
            name: 'Workplace ID',
            value: this.location.id,
          },
          ...this.visibleAttributes.filter((row) => !row.skipCopy),
        ];

      return attributes
        .map(({ name, value }) => `${name}: ${value}`)
        .join('\n')
        .trim();
    },
  },

  watch: {
    'photoScan.show': {
      handler(show) {
        if (!show) {
          this.photoScan.loading = false;
          this.photoScan.src = null;
        }
      },
    },
  },

  methods: {
    ...mapActions('auth', [
      'loginAs',
    ]),
    getRequestBankDetailsStatus(value) {
      if (value) {
        return 'ON';
      }

      return 'OFF';
    },
    isButtonOutlined(buttonName) {
      switch (buttonName) {
        // Close button always outlined.
        case 'CLOSE':
          return true;
        case 'VIEW_AS':
          return false;
        // Other action button outlined when `View as` showed (mean as main action).
        default:
          return this.visibleButtons.includes('VIEW_AS');
      }
    },
    getTaxReportingTypeValue({ taxReportingType }) {
      return ALL_TAX_REPORTING_TYPES.find(({ value }) => value === taxReportingType)?.label || null;
    },
    getTipsTypeValue({ tipsType }) {
      switch (tipsType) {
        case 'PERSONAL':
          return this.$t('tipsTypes.individual');
        case 'COMMON':
          return this.$t('tipsTypes.commonSimple');
        case 'COMMON_PRIORITY':
          return this.$t('tipsTypes.commonAdvanced');
        default:
          return null;
      }
    },
    getSharedCardValue({ isAllowedUsedSharedCard, isAllowedButtonSharedCard }) {
      if (isAllowedUsedSharedCard) {
        return 'In Use';
      }

      if (isAllowedButtonSharedCard) {
        return 'Allowed';
      }

      return 'Disabled';
    },
    isPhoto({ id }) {
      return [
        'ID_PHOTO_FRONT',
        'ID_PHOTO_BACK',
        'ID_PHOTO_SCAN',
      ].includes(id);
    },
    isReport({ id }) {
      return id === 'MONTHLY_REPORT';
    },
    getPhotoScanData(scanId) {
      if (['FRONT', 'BACK'].includes(scanId)) {
        return api.staff.getAdditionalInfoScan({
          staffId: this.staff.id,
          side: scanId,
        });
      }

      return api.staff.getStaffScan({
        staffId: this.staff.id,
        scanId,
      });
    },
    async openPhotoScan(scanId) {
      this.photoScan.loading = true;
      this.photoScan.show = true;

      try {
        const { data } = await this.getPhotoScanData(scanId);

        this.photoScan.src = window.URL.createObjectURL(data);
      } catch (e) {
        this.$showServerError(e);
        this.photoScan.show = false;
      } finally {
        this.photoScan.loading = false;
      }
    },
    async downloadPhotoScan(scanId) {
      try {
        const { data } = await this.getPhotoScanData(scanId);

        download(data, 'StaffIdInfo');
      } catch (e) {
        this.$showServerError(e);
      }
    },
    async getMonthlyReport() {
      const reportDate = DateTime
        .now()
        .minus({ month: 1 })
        .startOf('month')
        .toISODate();

      const reportData = {
        reportDate,
        workplaceId: this.location.id,
      };

      let responseData;

      try {
        if (this.staff?.isOwner === undefined) {
          responseData = await api.reporting.getMonthlyReport({
            ...reportData,
            userId: this.staff.id,
            role: 'OWNER',
          });
        } else {
          responseData = await api.reporting.getMonthlyReport({
            ...reportData,
            userId: this.staff.userId,
            role: 'STAFF',
          });
        }

        open(responseData.data);
        this.$showSuccess();
      } catch (e) {
        this.$alert({
          title: 'Report is unavailable',
          message: 'The user does not meet the report generation criteria: employment period, transaction count or included into exception list.',
          applyButton: this.$t('form.buttons.ok'),
        });
      }
    },
    async viewAs() {
      try {
        const query = await this.loginAs(this.staff);

        window.location.href = this.$router.resolve({
          name: 'token',
          query: {
            ...query,
            returnPath: this.$route.fullPath,
          },
        })?.href;
      } catch (e) {
        this.$showServerError(e);
      }
    },
    async loadInfo() {
      this.loading = true;

      try {
        this.info = {
          ...this.staff,
          location: this.location,
        };

        await Promise.all([
          this.loadAdditional(),
          this.loadDetails(),
          this.loadBankAccountDetails(),
        ]);
      } catch (e) {
        if (e?.response?.status !== 404) {
          this.$showServerError(e);
        }
      } finally {
        this.loading = false;
      }
    },
    async loadAdditional() {
      if (!this.isSomeAttributes([
        'FULL_LEGAL_NAME',
        'PASSPORT_ID',
        'COUNTRY_ID',
        'ID_PHOTO_FRONT',
        'ID_PHOTO_BACK',
        'APP_INSTALLED',
        'APP_ENTRY_DATE',
        'USAGE_FREQUENCY',
      ])) {
        return;
      }

      const { data } = await api.staff.getStaffIdInfo({ staffId: this.staff.id });

      this.info = {
        ...this.info,
        ...data,
      };
    },
    async loadDetails() {
      if (!this.isSomeAttributes([
        'STAFF_PAYOUT_ID',
        'LOCATION_NAME',
        'STAFF_TYPE',
        'TAX_REPORTING',
        'TIPS_TYPE',
        'PAYMENT_SYSTEM',
        'PAYMENT_STATUS',
        'SHARED_CARD',
        'APP_VERSION',
      ])) {
        return;
      }

      const { data } = await api.staff.getStaffDetails({ staffId: this.staff.id });
      const { location, ...info } = makeStaffDetails(data);

      this.info = {
        ...this.info,
        ...info,
        location: {
          ...this.info.location,
          ...location,
        },
      };
    },
    async loadBankAccountDetails() {
      if (!this.isSomeAttributes([
        'FIRST_NAME',
        'LAST_NAME',
        'ADDRESS',
        'BANK_NAME',
        'IBAN',
        'SWIFT_OR_BIC',
      ])) {
        return;
      }

      const { data } = await api.staff.getBankDetails({ staffId: this.staff.id });

      this.info = {
        ...this.info,
        ...data,
      };
    },
    isSomeAttributes(attributes) {
      return this.attributes.some((attr) => attributes.includes(attr));
    },
  },
};
</script>
