var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12 col-lg-6 col-xl-7"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-xl-6"},[_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.businessType'),"rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{attrs:{"items":_vm.types,"label":_vm.$t('form.labels.businessType'),"placeholder":_vm.$t('form.placeholders.select'),"label-key":"label","value-key":"value","filterable":"","required":"","error":errors[0],"disabled":!_vm.editable},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})]}}])}),(_vm.form.type)?[(_vm.showMasterLocationSelect)?_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.locationRole'),"rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{attrs:{"items":_vm.roleTypes,"label":_vm.$t('form.labels.locationRole'),"placeholder":_vm.$t('form.placeholders.select'),"label-key":"label","value-key":"value","filterable":"","required":"","error":errors[0],"disabled":!_vm.editable},model:{value:(_vm.form.isMasterLocation),callback:function ($$v) {_vm.$set(_vm.form, "isMasterLocation", $$v)},expression:"form.isMasterLocation"}})]}}],null,false,2251907253)}):_vm._e(),(!_vm.isMasterLocation)?[_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.tipCollectionType'),"rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{attrs:{"items":_vm.tipsTypes,"label":_vm.$t('form.labels.tipCollectionType'),"placeholder":_vm.$t('form.placeholders.select'),"label-key":"label","value-key":"value","filterable":"","required":"","error":errors[0],"disabled":!_vm.editable},model:{value:(_vm.form.tipsType),callback:function ($$v) {_vm.$set(_vm.form, "tipsType", $$v)},expression:"form.tipsType"}})]}}],null,false,2266092840)}),(_vm.showPaymentSystem)?_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.paymentSystem'),"rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{attrs:{"items":_vm.paymentSystems,"label":_vm.$t('form.labels.paymentSystem'),"placeholder":_vm.$t('form.placeholders.select'),"label-key":"label","value-key":"value","filterable":"","required":"","error":errors[0],"disabled":_vm.isPaymentSystemDisabled},model:{value:(_vm.form.paymentSystem),callback:function ($$v) {_vm.$set(_vm.form, "paymentSystem", $$v)},expression:"form.paymentSystem"}})]}}],null,false,729205718)}):_vm._e(),(_vm.showTaxReportingType)?_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.taxReportingType'),"rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{attrs:{"items":_vm.taxReportingTypes,"label":_vm.$t('form.labels.taxReportingType'),"placeholder":_vm.$t('form.placeholders.select'),"label-key":"label","value-key":"value","filterable":"","required":"","error":errors[0],"disabled":!_vm.editable},model:{value:(_vm.form.taxReportingType),callback:function ($$v) {_vm.$set(_vm.form, "taxReportingType", $$v)},expression:"form.taxReportingType"}})]}}],null,false,1107467536)}):_vm._e(),(_vm.showTaxesSelect)?_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.taxes'),"mode":"passive","rules":_vm.taxesValidationRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{attrs:{"items":_vm.taxTypes,"label":_vm.$t('form.labels.taxes'),"placeholder":_vm.$t('form.placeholders.select'),"label-key":"label","value-key":"value","filterable":"","error":errors[0],"disabled":_vm.isTaxesDisabled,"required":_vm.isTaxesRequired},model:{value:(_vm.form.taxes),callback:function ($$v) {_vm.$set(_vm.form, "taxes", $$v)},expression:"form.taxes"}})]}}],null,false,2604969744)}):_vm._e(),(_vm.showTipPayoutType)?_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.tipPayoutType'),"rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{attrs:{"items":_vm.tipPayoutTypeList,"label":_vm.$t('form.labels.tipPayoutType'),"placeholder":_vm.$t('form.placeholders.select'),"label-key":"label","value-key":"value","filterable":"","required":"","error":errors[0],"disabled":!_vm.editable},model:{value:(_vm.form.tipPayoutType),callback:function ($$v) {_vm.$set(_vm.form, "tipPayoutType", $$v)},expression:"form.tipPayoutType"}})]}}],null,false,1369212329)}):_vm._e(),(_vm.showCaseType)?_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.case'),"rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{attrs:{"items":_vm.caseTypes,"label":_vm.$t('form.labels.case'),"placeholder":_vm.$t('form.placeholders.select'),"label-key":"label","value-key":"value","filterable":"","required":"","error":errors[0],"disabled":!_vm.editable},model:{value:(_vm.form.caseType),callback:function ($$v) {_vm.$set(_vm.form, "caseType", $$v)},expression:"form.caseType"}})]}}],null,false,109878687)}):_vm._e(),(_vm.showPaymentPage)?_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.mainLandingPage'),"rules":"required","mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{attrs:{"items":_vm.paymentPages,"label":_vm.$t('form.labels.mainLandingPage'),"placeholder":_vm.$t('form.placeholders.select'),"label-key":"label","value-key":"value","filterable":"","required":"","error":errors[0]},model:{value:(_vm.form.paymentPage),callback:function ($$v) {_vm.$set(_vm.form, "paymentPage", $$v)},expression:"form.paymentPage"}})]}}],null,false,3310579909)}):_vm._e(),(_vm.showVenue)?_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.venueCharge'),"rules":_vm.venueDisabled ? {} : 'required|decimal|min_value:0|max_value:100',"mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{attrs:{"label":_vm.$t('form.labels.venueCharge'),"required":!_vm.venueDisabled,"disabled":_vm.venueDisabled,"error":errors[0]},model:{value:(_vm.form.workplaceFee),callback:function ($$v) {_vm.$set(_vm.form, "workplaceFee", $$v)},expression:"form.workplaceFee"}})]}}],null,false,2545745981)}):_vm._e(),(_vm.showTipout)?[_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.tipOut'),"rules":{ required: !_vm.primaryStaffDisabled },"mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{attrs:{"items":_vm.staffTypes,"label":_vm.$t('form.labels.tipOut'),"placeholder":_vm.$t('form.placeholders.select'),"label-key":"name","value-key":"value","filterable":"","required":!_vm.primaryStaffDisabled,"disabled":_vm.primaryStaffDisabled,"error":errors[0]},model:{value:(_vm.form.primaryStaff),callback:function ($$v) {_vm.$set(_vm.form, "primaryStaff", $$v)},expression:"form.primaryStaff"}})]}}],null,false,1968646155)}),_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.tipOutAmount'),"rules":_vm.primaryStaffDisabled ? {} : 'required|decimal|min_value:1|max_value:100',"mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{attrs:{"label":_vm.$t('form.labels.tipOutAmount'),"required":!_vm.primaryStaffDisabled,"disabled":_vm.primaryStaffDisabled,"error":errors[0]},model:{value:(_vm.form.primaryTipout),callback:function ($$v) {_vm.$set(_vm.form, "primaryTipout", $$v)},expression:"form.primaryTipout"}})]}}],null,false,3562797878)})]:_vm._e(),(_vm.showTimeSheet)?_c('validation-provider',{attrs:{"tag":"div","rules":"required","name":_vm.$t('form.labels.timeSheet'),"mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{attrs:{"items":_vm.timeTypes,"label":_vm.$t('form.labels.timeSheet'),"placeholder":_vm.$t('form.placeholders.select'),"label-key":"label","value-key":"value","error":errors[0],"required":""},model:{value:(_vm.form.timesheetType),callback:function ($$v) {_vm.$set(_vm.form, "timesheetType", $$v)},expression:"form.timesheetType"}})]}}],null,false,2974997683)}):_vm._e(),(_vm.isAdmin)?_c('ui-select',{attrs:{"items":_vm.abVariants,"label":"A/B Variants","placeholder":_vm.$t('form.placeholders.select'),"label-key":"label","value-key":"value"},model:{value:(_vm.form.abVariant),callback:function ($$v) {_vm.$set(_vm.form, "abVariant", $$v)},expression:"form.abVariant"}}):_vm._e(),_c('location-type-review',{attrs:{"form":_vm.form}}),(_vm.showMultipleTipsSwitcher)?_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('form.labels.multipleTips'),"mode":"passive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{attrs:{"items":_vm.MULTI_TIPS_MODES,"label":_vm.$t('form.labels.multipleTips'),"placeholder":_vm.$t('form.placeholders.select'),"error":errors[0]},model:{value:(_vm.form.multiTipsMode),callback:function ($$v) {_vm.$set(_vm.form, "multiTipsMode", $$v)},expression:"form.multiTipsMode"}})]}}],null,false,1074735415)}):_vm._e(),(_vm.showGroups)?_c('location-groups',{attrs:{"location":_vm.form,"groups":_vm.form.groups}}):_vm._e()]:[_c('location-sublocations',{attrs:{"location":_vm.form,"sublocations":_vm.form.sublocations}})]]:_vm._e()],2),_c('location-dashboard-access',{attrs:{"form":_vm.form,"data":_vm.data,"is-onboarding-loading":_vm.isOnboardingLoading,"disabled":!_vm.form.type},on:{"completed":function($event){return _vm.$emit('completed')},"activate":function($event){return _vm.$emit('activate')}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }