<template>
  <ui-card tag="form" :is-loading="isLoading">
    <template #header>
      <h4 class="card-title">
        {{ $t('locations.listTitle') }}
      </h4>
    </template>

    <div class="row mb-4">
      <div class="col-12 col-md-auto mt-1">
        <ui-input
          v-model="filter.location"
          :placeholder="$t('form.placeholders.locationNameOrId')"
          class="my-0"
          bordered
          search
          @keydown.enter.prevent="search"
        />
      </div>
      <div class="col-12 col-md-auto mt-1 my-0">
        <ui-select
          v-model="filter.country"
          :items="countries"
          placeholder="Location country"
          class="my-0"
          label-key="name"
          value-key="iso"
          @change="search"
          bordered
          large
        />
      </div>
      <div class="col-12 col-md-auto mt-1 my-0">
        <ui-select
          v-model="filter.taxReportingType"
          :items="taxReportingTypeList"
          :placeholder="$t('form.labels.taxReporting')"
          class="my-0"
          @change="search"
          bordered
          large
        />
      </div>
      <div class="col-12 col-md-auto mt-1 my-0">
        <ui-select
          v-model="filter.status"
          :items="statusList"
          :placeholder="$t('form.labels.status')"
          class="my-0"
          @change="search"
          bordered
          large
        />
      </div>
      <div class="col-12 col-md-auto mt-1 my-0">
        <ui-select
          v-model="filter.salesId"
          :items="salesFilters"
          class="my-0"
          bordered
          large
          @change="search"
        />
      </div>
      <div class="d-xl-none col-12 col-md-auto ml-auto mt-1">
        <ui-select
          :value="filter.sortByField"
          :items="sortList"
          placeholder="Sort by"
          class="w-auto my-0"
          bordered
          large
          @input="setSort({ sortBy: $event, sortDirection: 'ASC' })"
        />
      </div>
    </div>

    <ui-data-table
      :columns="columns"
      :data="items"
      :row-class="getRowClass"
      data-table="location"
      @sort="setSort"
    >
      <template #location-qr="{ row }">
        <a
          v-if="row.status !== 'NOT_ACTIVE'"
          href="javascript:void(0)"
          @click="openQr(row)"
          class="text-dark"
        >
          <ui-icon
            name="qr"
            size="16"
          />
        </a>
        <ui-icon
          v-else
          name="qr"
          size="16"
        />
      </template>

      <template #location-name="{ row }">
        <span v-if="selected" class="word-break-word">
          {{ row.name }}
        </span>
        <router-link
          v-else
          class="text-primary word-break-word"
          :to="{
            name: 'location-view',
            params: {
              locationId: row.id,
            },
          }"
        >
          {{ row.name }}
        </router-link>
      </template>

      <template #location-address-value="{ row }">
        <div class="word-break-word">
          {{ getFullAddress(row) }}
        </div>
      </template>

      <template #location-date-value="{ value }">
        {{ value | date }}
      </template>

      <template #location-tax-reporting-type-value="{ value }">
        <template v-if="value === 'SELF_REPORTING'">
          Self-reporting
        </template>
        <template v-else-if="value === 'EMPLOYER'">
          Employer reporting
        </template>
        <template v-else>
          Unknown
        </template>
      </template>

      <template #location-feeplan-value="{ value }">
        {{ value }}%
      </template>

      <template #location-tipstype-value="{ value }">
        <template v-if="value === 'COMMON'">
          Common
        </template>
        <template v-else-if="value === 'COMMON_PRIORITY'">
          Common advanced
        </template>
        <template v-else-if="value === 'PERSONAL'">
          Individual
        </template>
        <template v-else>
          Unknown
        </template>
      </template>

      <template #location-type-value="{ value }">
        <template v-if="value === 'RESTAURANT'">
          Restaurant
        </template>
        <template v-else-if="value === 'DELIVERY'">
          Delivery
        </template>
        <template v-else-if="value === 'BEAUTY_SALON'">
          Beauty salon
        </template>
        <template v-else-if="value === 'SPA'">
          SPA
        </template>
        <template v-else-if="value === 'HOTEL'">
          Hotel
        </template>
        <template v-else>
          Unknown
        </template>
      </template>

      <template #location-action="{ row }">
        <ui-loading v-if="row.loading" inline />
        <ui-action-down
          v-else
          :disabled="Boolean(selected || isLoading || row.loading)"
          :items="locationActions"
          @select="handleAction($event, row)"
        />
      </template>
    </ui-data-table>

    <ui-pagination
      v-if="total > pageSize"
      v-model="page"
      :total="total"
      :per-page="pageSize"
      :show-pages="7"
      class="mt-4"
      @input="update"
    />

    <qr-code
      :show="Boolean(showQr)"
      :location-id="showQr"
      @closed="closeQr"
    />
  </ui-card>
</template>

<script>
import { mapState } from 'vuex';
import api from '@/api';
import getCountry from '@/utils/getCountry';
import QrCode from '@/components/QrCode.vue';

export default {

  components: {
    QrCode,
  },

  props: {
    reload: Boolean,
    selected: Object,
  },

  data() {
    return {
      showQr: null,
      isLoading: false,
      filter: {
        country: undefined,
        location: undefined,
        status: 'NEW,ACTIVE',
        sortByField: undefined,
        sortDirection: undefined,
        taxReportingType: undefined,
        salesId: undefined,
      },
      sortList: [
        {
          value: 'payoutId',
          name: 'ID',
        },
        {
          value: 'name',
          name: 'Name',
        },
        {
          value: 'createdDate',
          name: 'Date',
        },
      ],
      items: [],
      page: 1,
      total: 0,
      pageSize: 10,
      columns: [
        {
          name: 'location-qr',
          isVisible: ({ row }) => row.isShowQR,
        },
        {
          name: 'location-id',
          label: this.$t('table.columns.easytipId'),
          prop: 'payoutId',
          empty: this.$t('table.empty.easytipId'),
          sorting: true,
        },
        {
          name: 'location-name',
          label: this.$t('table.columns.name'),
          prop: 'name',
          sorting: true,
        },
        {
          name: 'location-address',
          label: this.$t('table.columns.address'),
          prop: 'address',
          empty: this.$t('table.empty.address'),
        },
        {
          name: 'location-quantity',
          label: this.$t('table.columns.locationQuantity'),
          prop: 'locationQuantity',
          empty: '0',
        },
        {
          name: 'location-date',
          label: this.$t('table.columns.date'),
          prop: 'createdDate',
          empty: this.$t('table.empty.date'),
          sorting: true,
        },
        {
          name: 'location-tax-reporting-type',
          label: this.$t('table.columns.taxReporting'),
          prop: 'taxReportingType',
          empty: this.$t('table.empty.taxReporting'),
        },
        {
          name: 'location-feeplan',
          label: this.$t('table.columns.feePlan'),
          prop: 'feePlan',
          empty: this.$t('table.empty.feePlan'),
        },
        {
          name: 'location-tipstype',
          label: this.$t('table.columns.tips'),
          prop: 'tipsType',
          empty: this.$t('table.empty.tips'),
        },
        {
          name: 'location-type',
          label: this.$t('table.columns.type'),
          prop: 'type',
          empty: this.$t('table.empty.type'),
        },
        {
          name: 'location-action',
        },
      ],
    };
  },

  computed: {
    ...mapState({
      salesPeople: (state) => state.settings.salesPeople,
    }),
    salesFilters() {
      return [
        { value: undefined, name: 'All managers' },
        { value: 'EMPTY', name: 'N/A' },
        ...this.salesPeople.map((sales) => ({
          value: sales.id, name: sales.fio,
        })),
      ];
    },
    start() {
      return (this.page - 1) * this.pageSize;
    },
    formatFilter() {
      const {
        name,
        payoutId,
        location,
        ...filterData
      } = this.filter;

      let param;

      if (!location) {
        param = null;
      } else if (!Number.isNaN(+location)) {
        param = {
          payoutId: location,
        };
      } else {
        param = {
          name: location,
        };
      }

      return {
        ...filterData,
        ...param,
      };
    },
    apiConfig() {
      return {
        start: this.start,
        pageSize: this.pageSize,
        ...this.formatFilter,
      };
    },
    urlConfig() {
      return {
        page: this.page,
        ...this.formatFilter,
      };
    },
    statusList() {
      return [
        { name: `${this.$t('status.NEW')} & ${this.$t('status.ACTIVE')}`, value: 'NEW,ACTIVE' },
        { name: this.$t('status.ALL'), value: undefined },
        { name: this.$t('status.NEW'), value: 'NEW' },
        { name: this.$t('status.ACTIVE'), value: 'ACTIVE' },
        { name: this.$t('status.NOT_ACTIVE'), value: 'NOT_ACTIVE' },
      ];
    },
    locationActions() {
      return [
        {
          id: 'edit',
          label: this.$t('form.buttons.edit'),
        },
        {
          id: 'delete',
          label: this.$t('form.buttons.delete'),
        },
      ];
    },
    taxReportingTypeList() {
      return [
        {
          name: 'All types',
          value: undefined,
          visible: true,
        },
        {
          name: 'Self-reporting',
          value: 'SELF_REPORTING',
          visible: true,
        },
        {
          name: 'Employer reporting',
          value: 'EMPLOYER',
          visible: this.$apiSwitch?.selectedValue !== 'uae',
        },
        {
          name: 'Mix reporting',
          value: 'MIX_REPORTING',
          visible: this.$apiSwitch?.selectedValue !== 'uae',
        },
      ].filter(({ visible }) => visible);
    },
    countries() {
      return this.$getCountries({ empty: true });
    },
  },

  watch: {
    reload: {
      immediate: true,
      async handler(value) {
        if (value) {
          await this.loadItems();

          this.$emit('update:reload', false);
        }
      },
    },
    '$route.query': {
      immediate: true,
      handler({ page, ...params }) {
        this.page = parseInt(page || 1, 10);
        this.filter = {
          ...this.filter,
          ...params,
          location: params.name || params.payoutId,
        };

        this.loadItems();
      },
    },
  },

  methods: {
    openQr(item) {
      this.showQr = item.id;
    },
    closeQr() {
      this.showQr = null;
    },
    setSort({ sortBy, sortDirection }) {
      this.filter.sortByField = sortBy;
      this.filter.sortDirection = sortDirection;
      this.search();
    },
    getRowClass({ row }) {
      if (this.selected?.id === row.id) {
        return 'bg-info text-white';
      }

      if (row.status === 'NOT_ACTIVE') {
        return 'data-table__row--not-active text-grey';
      }

      if (row.status === 'NEW') {
        return 'data-table__row--new';
      }

      return null;
    },
    getFullAddress({ country, city, address }) {
      const countryName = getCountry(country, null)?.name;

      return [
        countryName,
        city,
        address,
      ].filter((v) => v).join(', ');
    },
    setItems(items) {
      this.items = items.map((item) => ({
        ...item,
        loading: false,
      }));
    },
    async loadItems() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      this.update();

      try {
        const {
          data: {
            data,
            recordsFiltered,
            totalRecords,
          },
        } = await api.locations.getLocations(this.apiConfig);

        if (data) {
          this.total = totalRecords;
          this.setItems(data);
        }
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.isLoading = false;
      }
    },
    search() {
      this.page = 1;
      this.loadItems();
    },
    select(item) {
      this.$emit('select', item);
    },
    async deactivate(item) {
      await api.locations.deactivateLocation(item);

      item.status = 'NOT_ACTIVE';
    },
    async activate(item) {
      await api.locations.activateLocation(item);

      item.status = 'ACTIVE';
    },
    update() {
      this.$emit('update', {
        query: this.urlConfig,
      });
    },
    sort(attribute) {
      if (attribute !== this.filter.sortByField) {
        this.filter.sortDirection = 'ASC';
      } else {
        this.filter.sortDirection = this.filter.sortDirection === 'ASC' ? 'DESC' : 'ASC';
      }

      this.filter.sortByField = attribute;
      this.update();
    },
    async deleteLocation({ locationId }) {
      try {
        await api.locations.deleteLocation({ locationId });
        if (this.items.length === 1 && this.page > 1) {
          this.page -= 1;
        }
        await this.loadItems();
      } catch (e) {
        this.$showServerError(e);
      }
    },
    async handleAction(event, item) {
      if (event === 'edit') {
        this.select(item);

        return;
      }

      item.loading = true;

      if (event === 'delete') {
        await this.deleteLocation({ locationId: item.id });

        return;
      }

      try {
        if (event === 'deactivate') {
          await this.deactivate(item);
        }
      } catch (e) {
        this.$showServerError(e);
      } finally {
        item.loading = false;
      }

      try {
        if (event === 'activate') {
          await this.activate(item);
        }
      } catch (e) {
        this.$showServerError(e);
      } finally {
        item.loading = false;
      }
    },
  },
};
</script>
