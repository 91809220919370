<template>
  <validation-observer tag="form" #default="{ invalid, handleSubmit }">
    <ui-modal-alert
      v-bind="$attrs"
      :apply-disabled="invalid"
      apply-button="Apply"
      @apply="handleSubmit(handleApply)"
      @cancel="handleCancel"
      no-close
    >
      <div class="col-11 mx-auto text-left mt-5">
        <validation-provider
          v-if="showStaffType"
          tag="div"
          :name="$t('form.labels.staffType')"
          rules="required"
        >
          <ui-select
            v-model="form.staffType"
            :items="staffTypes"
            :label="$t('form.labels.staffType')"
            :placeholder="$t('form.placeholders.select')"
            label-key="name"
            value-key="value"
            required
          />
        </validation-provider>

        <validation-provider
          v-if="showTaxReportingType"
          tag="div"
          name="Tax reporting"
          rules="required"
        >
          <ui-select
            v-model="form.taxReportingType"
            :items="taxReportingTypes"
            label-key="label"
            value-key="value"
            label="Tax reporting"
            :placeholder="$t('form.placeholders.select')"
            :disabled="disableTaxReportingType"
            required
          />
        </validation-provider>

        <validation-provider
          v-if="showPaymentSystem(form)"
          tag="div"
          :name="$t('form.labels.paymentSystem')"
        >
          <ui-select
            v-model="tariffPaymentSystem"
            :items="paymentSystems"
            :label="$t('form.labels.paymentSystem')"
            :placeholder="$t('form.placeholders.select')"
            :disabled="disabled.paymentSystem"
          />
        </validation-provider>

        <validation-provider
          v-if="location.isNymcardEnabled"
          tag="div"
          :name="$t('form.labels.nymCard')"
        >
          <ui-select
            v-model="form.enableNymCard"
            :items="ENABLE_BOOLEAN_SELECT_VALUES"
            :label="$t('form.labels.nymCard')"
            :placeholder="$t('form.placeholders.select')"
            :disabled="disableNymcardSelector"
          />
        </validation-provider>

        <validation-provider
          v-if="showOrdersPermission"
          tag="div"
          name="Permissions for orders"
        >
          <ui-select
            v-model="form.ordersPermissionRole"
            :items="ORDERS_PERMISSION_ROLES"
            class="change-orders-permission-modal__select"
            label="Permissions for orders"
            :placeholder="$t('form.placeholders.select')"
            label-key="label"
            value-key="value"
          />
        </validation-provider>

        <template v-if="showPrimaryEmployer">
          <ui-input
            v-model="form.payrollId"
            label="Payroll ID"
          />

          <validation-provider
            tag="div"
            name="Primary employer ID"
            rules="required"
          >
            <ui-select
              v-model="form.primaryEmployer"
              :placeholder="$t('form.placeholders.select')"
              :remote-method="getPrimaryEmployers"
              hide-empty-dropdown
              hide-dropdown-loading
              label-key="label"
              value-key="id"
              label="Primary employer ID"
              value-original
              required
            />
          </validation-provider>
        </template>
      </div>
    </ui-modal-alert>
  </validation-observer>
</template>

<script>
import { mapGetters } from 'vuex';
import { PAYMENT_SYSTEMS } from '@/config/paymentSystems';
import { MAIN_TAX_REPORTING_TYPES } from '@/config/taxReportingTypes';
import { NOT_MIX_REPORTING_TYPES } from '@/config/notMixReportingTypes';
import { ENABLE_BOOLEAN_SELECT_VALUES } from '@/config/enableBooleanSelectValues';
import { ORDERS_PERMISSION_ROLES } from '@/config/ordersPermissionRoles';
import isDisableTaxReportingType from '@/utils/disableTaxReportingType';
import api from '@/api';

export default {
  name: 'StaffEditModal',

  props: {
    staff: {
      type: Object,
      required: true,
    },
    location: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    form: {
      staffType: null,
      taxReportingType: '',
      enableNymCard: null,
      paymentSystem: 'STRIPE',
      payoutPricePlanType: null,
      payrollId: null,
      primaryEmployer: {},
      ordersPermissionRole: null,
    },
    disabled: {
      paymentSystem: false,
      enableNymCard: false,
    },
    ENABLE_BOOLEAN_SELECT_VALUES,
    ORDERS_PERMISSION_ROLES,
  }),

  computed: {
    ...mapGetters('auth', [
      'isAdminOrSales',
      'isOwner',
      'isAdmin',
      'isSupportAdmin',
    ]),
    isUk() {
      return this.location.country === 'gb';
    },
    isSwitzerland() {
      return this.location.country === 'ch';
    },
    isGermany() {
      return this.location.country === 'de';
    },
    isUAE() {
      return this.location.country === 'ae';
    },
    isCommon() {
      return this.location.tipsType === 'COMMON' || this.location.tipsType === 'COMMON_PRIORITY';
    },
    staffTypes() {
      return this.$store.getters['handbook/staffTypes']
        .filter(({ types }) => types.includes(this.location.type))
        .filter(({ countries }) => countries.includes(this.location.country));
    },
    taxReportingTypes() {
      return MAIN_TAX_REPORTING_TYPES;
    },
    paymentSystems() {
      return PAYMENT_SYSTEMS.filter(({ countries }) => {
        return countries.includes(this.location.country);
      });
    },
    disableTaxReportingType() {
      return isDisableTaxReportingType(this.location.taxReportingType);
    },
    tariffPaymentSystem: {
      get() {
        return this.form.paymentSystem;
      },
      set(value) {
        const option = this.paymentSystems.find((config) => config.value === value);

        if (option) {
          this.form = {
            ...this.form,
            ...option.attributes,
          };
        }
      },
    },
    showStaffType() {
      return this.isAdminOrSales || this.isOwner;
    },
    showTaxReportingType() {
      return this.isAdminOrSales
        && (this.isUk || this.isSwitzerland);
    },
    showPrimaryEmployer() {
      return this.isAdminOrSales
        && (this.isUk || this.isSwitzerland)
        && this.form.taxReportingType === 'EMPLOYER';
    },
    disableNymcardSelector() {
      return this.disabled.enableNymCard || this.form.paymentSystem !== 'CHECKOUT_UAE';
    },
    showOrdersPermission() {
      return this.location.isQrBillEnabled && this.staff.isOwner;
    },
  },

  watch: {
    tariffPaymentSystem(value) {
      if (value === 'CHECKOUT' && this.form.enableNymCard) {
        this.form.enableNymCard = false;
      }
    },
  },

  created() {
    const {
      staffType,
      taxReportingType,
      enableNymCard,
      paymentSystem,
      payoutPricePlanType,
      payrollId,
      primaryEmployer,
      transactionsAmount,
      ordersPermissionRole,
    } = this.staff;

    this.form.staffType = staffType;
    this.form.taxReportingType = taxReportingType;
    this.form.enableNymCard = enableNymCard;
    this.form.payrollId = payrollId;
    this.form.paymentSystem = paymentSystem;
    this.form.payoutPricePlanType = payoutPricePlanType;
    this.form.primaryEmployer = primaryEmployer ? this.formatPayoutItem(primaryEmployer) : null;
    this.form.ordersPermissionRole = ordersPermissionRole;

    if (NOT_MIX_REPORTING_TYPES.includes(this.location.taxReportingType)) {
      this.form.taxReportingType = this.location.taxReportingType;
    }

    if (paymentSystem === 'CHECKOUT_UAE' && transactionsAmount !== 0 && !this.isSupportAdmin) {
      this.disabled.paymentSystem = true;
    }

    if (enableNymCard) {
      this.disabled.enableNymCard = true;
    }
  },

  methods: {
    formatPayoutItem({ name, payoutId }) {
      return `${name} (ID ${payoutId})`;
    },
    async getPrimaryEmployers(query) {
      const {
        data: {
          data: locations,
        },
      } = await api.locations.getLocations({ payoutId: query });

      return locations.map((item, index) => ({
        id: item.id,
        name: item.name,
        payoutId: item.payoutId,
        label: this.formatPayoutItem(item),
      }));
    },
    showPaymentSystem({ taxReportingType }) {
      return this.isAdminOrSales
        && (this.isUk || this.isGermany || this.isUAE)
        && taxReportingType === 'SELF_REPORTING'
        && !this.isCommon;
    },
    async handleApply() {
      const request = {
        locationId: this.location.id,
        staffId: this.staff.id,
        id: this.staff.id,
        payrollId: this.form.payrollId,
        staffType: this.form.staffType,
      };

      if (this.showTaxReportingType) {
        if (typeof this.form.primaryEmployer === 'string') {
          this.form.primaryEmployer = {
            ...this.staff.primaryEmployer,
            label: this.formatPayoutItem(this.staff.primaryEmployer),
          };
        }

        if (this.form.primaryEmployer) {
          const {
            label,
            ...primaryEmployer
          } = this.form.primaryEmployer;

          request.primaryEmployer = primaryEmployer;
        } else {
          request.primaryEmployer = null;
        }

        request.taxReportingType = this.form.taxReportingType;
      }

      if (this.showPaymentSystem(this.form)) {
        request.paymentSystem = this.form.paymentSystem;
        request.payoutPricePlanType = this.form.payoutPricePlanType;
      } else if (this.showPaymentSystem(this.staff)) {
        request.paymentSystem = 'STRIPE';
      } else {
        request.paymentSystem = this.staff.paymentSystem;
        request.payoutPricePlanType = this.staff.payoutPricePlanType;
      }

      if (this.location.isNymcardEnabled) {
        request.enableNymCard = this.form.enableNymCard;
      }

      if (this.showOrdersPermission) {
        request.ordersPermissionRole = this.form.ordersPermissionRole;
      }

      try {
        await api.locations.updateStaff(request);

        this.$showSuccess();
        this.$emit('apply');
      } catch (e) {
        this.$showServerError(e);
      }
    },
    handleCancel() {
      this.$emit('cancel');
    },
  },
};
</script>
